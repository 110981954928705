// import Main from "./Pages/Main/Main";
import Menu from "./Pages/Menu/Menu";
import ProductPage from "./Pages/ProductPage/ProductPage";
import BuyUsPage from "./Pages/BuyUsPage/BuyUsPage";
import AboutUsPage from "./Pages/AboutUsPage/AboutUsPage";
import CategoryProductsPage from "./Pages/CategoryProductsPage/CategoryProductsPage";
import OrderPage from "./Pages/OrderPage/OrderPage";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Registrer";

import { createBrowserRouter } from "react-router-dom";
import PersonalCabinet from "./Pages/PersonalCabinet/PersonalCabinet";
import Logout from "./Pages/Logout/Logout";
import ExchangePolicyPage from "./Pages/ExchangePolicyPage/ExchangePolicyPage";
import OfferContractPage from "./Pages/OfferContractPage/OfferContractPage";
import UserAgreementPage from "./Pages/UserAgreementPage/UserAgreementPage";
import SuccessPage from "./Pages/SuccessPage/SuccessPage";
import ForPartnersTest from "./Pages/ForPartnersTest/ForPartnersTest";
import TestProductPage from "./Pages/TestProductPage/TestProductPage";
import TestMainPage from "./Pages/TestMainPage/TestMainPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <TestMainPage />,
  },
  // {
  //   path: "/test-main",
  //   element: <TestMainPage />
  // },
  {
    path: "/menu",
    element: <Menu />,
  },

  {
    path: "/buy-us",
    element: <BuyUsPage />,
  },

  {
    path: "/about-us",
    element: <AboutUsPage />,
  },

  {
    path: "/for-partners",
    element: <ForPartnersTest />,
  },

  {
    path: "/category/:categoryId",
    element: <CategoryProductsPage />,
  },

  {
    path: "/products/:productId",
    element: <ProductPage />,
  },
  {
    path: "/test/products/:productId",
    element: <TestProductPage />
  },
  {
    path: "/order",
    element: <OrderPage />,
  },

  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/register",
    element: <Register />,
  },

  {
    path: "/personal-account",
    element: <PersonalCabinet />,
  },

  {
    path: "/logout",
    element: <Logout />,
  },

  {
    path: "/exchange-policy",
    element: <ExchangePolicyPage />,
  },

  {
    path: "/offer-contract",
    element: <OfferContractPage />,
  },

  {
    path: "/user-agreement",
    element: <UserAgreementPage />,
  },

  {
    path: "/success",
    element: <SuccessPage />,
  },
]);

export default router;
