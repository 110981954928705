import { Link } from "react-router-dom";
import { useGetAllCategoriesQuery } from "../../app/store";
import "../Menu/Menu.css";
import Loading from "../../Components/Loading/Loading";

function Menu() {
  const { data, error, isLoading } = useGetAllCategoriesQuery();
  return (
    <main>
      <div className="subcategory--container">
        {isLoading ? (
          <Loading />
        ) : error ? (
          <p>An error occured</p>
        ) : (
          <>
            {data.results.map((category) => (
              <div className="subcategory">
                <h1 className="subcategory--title" key={category.id}>
                  {category.title}
                </h1>

                {category.categories.map((cat) => (
                  <div className="category--card">
                    <Link
                      key={cat.category_id}
                      to={{
                        pathname: `/category/${cat.category_id}`,
                        state: { catName: cat.category_name },
                      }}
                    >
                      <img src={cat.category_img} alt={cat.category_name} />
                      <h3 className="category--name">{cat.category_name}</h3>
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </main>
  );
}

export default Menu;
